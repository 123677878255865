.testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}

.left-t{
    display: flex;
    flex-direction: column;
    gap: 2rem;  
    flex: 1 1;
    text-transform: uppercase;
    color: white;
}
.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}
.left-t>:nth-child(2),
.left-t>:nth-child(3){
font-size: 3rem;
font-weight: bold;
}
.left-t>:nth-child(5)>:nth-child(1){
    color: var(--orange);
}
.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}
.right-t{
    flex: 1;
    position: relative;
}
.right-t>img{
    position: relative;
    width: 18rem;
    top: 1.5rem;
    right: -18.2rem;
    height: 20rem;
    object-fit: contain;
}
.right-t>:nth-child(1){
    position: absolute;
    border: 2px solid orange;
    width: 13rem;
    height: 19rem;
    right:14rem;
    top: 0.2rem;
    background-color: transparent;
}
.right-t>:nth-child(2){
    position: absolute;
    width: 15rem;
    height: 19rem;
    background: var(--planCard);
    top:3.8rem;
    right: 9rem; 
    /* z-index: -1; */
}
.arrows{
    display: flex;
    position: absolute;
    gap: 1rem;
    bottom: 1rem;
    left: 9rem;
}
.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .testimonials{
        flex-direction: column;
    }
    .left-t>:nth-child(2),
    .left-t>:nth-child(3){
        font-size: xx-large;
    }
.right-t{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.right-t>div{
    position: relative;
    display: none;
}
.right-t>img{
    position: relative;
    align-self: center;
    right: 0;
}
.right-t>:last-child{
    display: block;
    bottom: 0;
    left: 0;
}
}