.hero {
    display: flex;
    justify-content: space-between;
}

.left-h {
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
}

.right-h {
    flex: 1;
    position: relative;
    background-color: var(--orange);
}

.hero-text {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: wheat;
    text-overflow: inherit;
}

.hero-text>div:nth-of-type(3) {
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

.figures {
    display: flex;
    gap: 2rem;
    margin-top: 1.5rem;
}

.figures>div {
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1) {
    font-size: 2rem;
    font-weight: bold;
    color: white;
}

.figures>div>span:nth-of-type(2) {
    color: var(--gray);
    text-transform: uppercase;
}

.hero-button {
    display: flex;
    gap: 1rem;
    ;
    margin-top: 1rem;
    font-weight: normal;
}

.hero-button>:nth-child(1) {
    color: white;
    background-color: var(--orange);
    width: 8rem;
}

.hero-button>:nth-child(2) {
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
}

.right-h>.btn {
    position: absolute;
    right: 2rem;
    top: 2rem;
    color: black;
}

.heart-rate {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;
    background-color: var(--gray);
    padding: 2rem;
    align-items: start;
    border-radius: 5px;
    position: relative;
    top: 7rem;
    right: -7rem;
}

.heart-rate>:nth-child(1) {
    width: 2.5rem;
}

.heart-rate>:nth-child(2) {
    color: lightslategray;
    font-weight: bold;
}

.heart-rate>:nth-child(3) {
    color: white;
    font-size: 1.5rem;
}

.hero-image {
    position: absolute;
    width: 35rem;
    right: 5rem;
    top: 12rem;
    
}

.space-create {
    padding: 8rem;

}

.hero-image-back {

    position: absolute;
    right: 16.5rem;
    width: 15rem;
    top: 9rem;
    z-index: -1;
}

.calories {
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    /* padding-right: 2rem; */
    width: fit-content;
    position: absolute;
    top: 36rem;
    right: 38rem;
}

.calories>img {
    width: 3.5rem;
    height: 5rem;
}

.calories>div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 8rem;
}

.calories>div>:nth-child(2) {
    font-size: 25px;
    color: wheat;
    background-color: var(--caloryCard);
}

.calories>div>:nth-child(1) {
    font-weight: bold;
    color: var(--gray);
}
.blur-hero{
    height: 30rem;
    width: 30rem;
    left: 0;
}
@media screen and (max-width: 768px) {
   .hero{
    
    flex-direction: column;
   } 
   .hero-text{
    margin-top: -12rem;
    font-size: xx-large;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
   }
   .hero-text>div:nth-child(3){
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
   }
   .hero-button{
    position: relative;
    height: 3rem;
    left:-1rem
   }
   .hero-button>button:nth-of-type(2){
    font-size: 0.9rem;

   }
   .figures>div>span:nth-of-type(1){
    font-size: large;
   }
   .figures>div>span:nth-of-type(2){
    font-size: small;
   }
   .right-h{
    position: relative;
    background: none;
   }
   .right-h>button{
    position: relative;
    left:12rem;
    width:7rem
   }
   .heart-rate{
    width: 3rem;
    height: 3.5rem;
    left: 1rem;
    top: 2rem;
    gap: 0.7rem;
   }
   .heart-rate>img{
    margin-top: -2.5rem;
    padding-top: 0.2rem;
    width: 1rem;
    height: 4rem;
   }
   .calories{
    position: relative;
    left: 2rem;
    top: -1rem;
   }
   .calories>img{
    width: 3rem;
   }
   .calories>div>:nth-child(2){
    color:white;
    /* font-size: 1rem; */
   }
   .hero-image{
    position: relative;
    top: -2rem;
    width: 21rem;
    left:3.5rem;
    align-self: center;
   }
   .hero-image-back{
    width: 10rem;
    left: 7rem;
    top: 1.5rem;
   }




}